export const basePath = process.env.REACT_APP_BASE_URL || 'http://localhost:8002/api/v1'
// export const basePath = process.env.REACT_APP_BASE_URL || 'https://console.globalcloudsol.com/api/v1'

export const APIurls = {
    register: '/auth/register',
    login: '/auth/login',
    logout: '/auth/logout',
    authenticate: '/auth/authenticate',
    creator: '/creators',
    brand: '/brand'
}
