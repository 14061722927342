// src/Components/Listings.js

import React, { useState } from "react";
import { Container, Button, Spinner } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import PropTypes from "prop-types";
import "./console.css";

const Listings = ({
    deployments,
    serviceDisplayNames,
    loading,
    page,
    totalPages,
    sortConfig,
    handleSort,
    handlePageChange,
    handleDeleteDeploy,
    activeTab,
    allServices,
    selectedPlatform,
    selectedInfra,
    handleFilterChange,
    getStatusClass
}) => {
    const [deletionLoading, setDeletionLoading] = useState({});

    const handleDelete = async (deployment) => {
        // Set loading state for this specific deployment
        setDeletionLoading(prev => ({ ...prev, [deployment.deploymentId]: true }));

        await handleDeleteDeploy(deployment);

        // Reset loading state after deletion is complete
        setDeletionLoading(prev => ({ ...prev, [deployment.deploymentId]: false }));
    };
    return (
        <Container>
            <h2 style={{ marginBottom: "30px", marginTop: "80px", fontSize: "24px", fontWeight: "bold", color: "#343a40", textAlign: "center" }}>
                Deployment Listings
            </h2>

            {/* Tab Navigation */}
            <div style={{ margin: "30px auto", textAlign: "center" }}>
                <Button
                    className={`btn btn-dark m-2 ${activeTab === 'all' ? 'active' : ''}`}
                    onClick={() => handleFilterChange('activeTab', 'all')}
                >
                    All
                </Button>

                {allServices.map(({ service, displayName }) => (
                    <Button
                        key={service}
                        className={`btn btn-dark m-2 ${activeTab === service ? 'active' : ''}`}
                        onClick={() => handleFilterChange('activeTab', service)}
                    >
                        {displayName}
                    </Button>
                ))}
                <br />

                <Button
                    className={`btn btn-dark m-2 ${activeTab === 'create-pending' ? 'active' : ''}`}
                    onClick={() => handleFilterChange('activeTab', 'create-pending')}
                >
                    Create Pending
                </Button>

                <Button
                    className={`btn btn-dark m-2 ${activeTab === 'create-success' ? 'active' : ''}`}
                    onClick={() => handleFilterChange('activeTab', 'create-success')}
                >
                    Create Success
                </Button>

                <Button
                    className={`btn btn-dark m-2 ${activeTab === 'create-failure' ? 'active' : ''}`}
                    onClick={() => handleFilterChange('activeTab', 'create-failure')}
                >
                    Create Failure
                </Button>

                <Button
                    className={`btn btn-dark m-2 ${activeTab === 'destroy-pending' ? 'active' : ''}`}
                    onClick={() => handleFilterChange('activeTab', 'destroy-pending')}
                >
                    Destroy Pending
                </Button>

                <Button
                    className={`btn btn-dark m-2 ${activeTab === 'destroy-success' ? 'active' : ''}`}
                    onClick={() => handleFilterChange('activeTab', 'destroy-success')}
                >
                    Destroy Success
                </Button>

                <Button
                    className={`btn btn-dark m-2 ${activeTab === 'destroy-failure' ? 'active' : ''}`}
                    onClick={() => handleFilterChange('activeTab', 'destroy-failure')}
                >
                    Destroy Failure
                </Button>
            </div>


            {/* Filter Dropdowns */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <select value={selectedPlatform} onChange={(e) => handleFilterChange('selectedPlatform', e.target.value)} className="form-select m-2">
                    <option value="">Filter by Platform</option>
                    <option key="aws" value="aws">AWS</option>
                    <option key="gcp" value="gcp">GCP</option>
                    <option key="azure" value="azure">Azure</option>
                </select>

                <select value={selectedInfra} onChange={(e) => handleFilterChange('selectedInfra', e.target.value)} className="form-select m-2">
                    <option value="">Filter by Infra</option>
                    <option value="create">Create</option>
                    <option value="destroy">Destroy</option>
                </select>
            </div>


            {
                // loading ? (
                //     <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                //         <Spinner animation="border" role="status" size="lg">
                //             <span className="visually-hidden">Loading...</span>
                //         </Spinner>
                //     </div>
                // ) : (
                deployments.length > 0 ? (
                    <>
                        <table className="deployments-table">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('Sr')}>Sr {sortConfig.key === 'Sr' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                    <th onClick={() => handleSort('platform')}>Platform {sortConfig.key === 'platform' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                    <th onClick={() => handleSort('service')}>Service {sortConfig.key === 'service' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                    <th onClick={() => handleSort('infra')}>Infra {sortConfig.key === 'infra' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                    <th onClick={() => handleSort('prefix')}>Prefix {sortConfig.key === 'prefix' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                    <th onClick={() => handleSort('status')}>Status {sortConfig.key === 'status' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                    <th>User</th>
                                    <th>Deployment Id</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {deployments.map((deployment, index) => (
                                    <tr key={deployment.deploymentId} className={getStatusClass(deployment.status)}>
                                        <td data-label="Sr">{(page - 1) * 100 + index + 1}</td>
                                        <td data-label="Platform">{deployment.platform}</td>
                                        <td data-label="Service">{serviceDisplayNames[deployment.service] || deployment.service}</td>
                                        <td data-label="Infra">{deployment.infra}</td>
                                        <td data-label="Prefix">{deployment.prefix}</td>
                                        <td data-label="Status">{deployment.status}</td>
                                        <td data-label="Deployment Id">{deployment.username}</td>
                                        <td data-label="Deployment Id">{deployment.deploymentId}</td>
                                        {/* <td>
                                            {deployment.infra == "create" && deployment.status == "create-success" && (
                                                <Button variant="danger" size="sm" onClick={() => handleDeleteDeploy(deployment)}>
                                                    <FaTrash /> Destroy
                                                </Button>
                                            )}
                                        </td> */}
                                        <td>
                                            {deployment.infra == "create" && deployment.status == "create-success" && (
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => handleDelete(deployment)}
                                                    disabled={deletionLoading[deployment.deploymentId]}
                                                >
                                                    {deletionLoading[deployment.deploymentId] ? (
                                                        <Spinner animation="border" size="sm" role="status" />
                                                    ) : (
                                                        <><FaTrash /> Destroy</>
                                                    )}
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Pagination */}
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Button
                                className="btn btn-dark m-2"
                                disabled={page === 1}
                                onClick={() => handlePageChange(page - 1)}
                            >
                                Previous
                            </Button>
                            <span style={{ margin: '0 15px' }}>Page {page} of {totalPages}</span>
                            <Button
                                className="btn btn-dark m-2"
                                disabled={page === totalPages}
                                onClick={() => handlePageChange(page + 1)}
                            >
                                Next
                            </Button>
                        </div>
                    </>
                ) : (
                    <div style={{
                        textAlign: 'center',
                        padding: '50px',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '10px',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                        color: '#6c757d',
                    }}>
                        <img
                            src="/images/02.png"
                            alt="No Deployments"
                            style={{ maxWidth: '150px', marginBottom: '20px' }}
                        />
                        <h3>No Deployments Found</h3>
                        <p>Please adjust your filters or check back later.</p>
                    </div>
                )
                // )
            }
        </Container>
    );
};

// Prop Types for type checking
Listings.propTypes = {
    deployments: PropTypes.array.isRequired,
    serviceDisplayNames: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    sortConfig: PropTypes.shape({
        key: PropTypes.string,
        direction: PropTypes.oneOf(['asc', 'desc']),
    }).isRequired,
    handleSort: PropTypes.func.isRequired,
    handlePageChange: PropTypes.func.isRequired,
    handleDeleteDeploy: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
    allServices: PropTypes.array.isRequired,
    selectedPlatform: PropTypes.string.isRequired,
    selectedInfra: PropTypes.string.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
};

export default Listings;
