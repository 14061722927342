import './styles.css';
import './Global.scss';
import { Route, Routes } from 'react-router-dom';
import Login from './Components/Login';
import Signup from './Components/Signup';
import Navbar from './Components/Navbar';
import Foter from './Components/Foter';
import ZagitThreeTabs from './Components/ZagitThreeTabs';
import ProtectedRoute from './Components/ProtectedRoute';  // Import ProtectedRoute

function App() {
  return (
    <div className="App">
      <Navbar /> {/* Navbar will show up on all routes */}
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        {/* Protected Routes using ProtectedRoute */}
        <Route
          path="/"
          element={
            // <ProtectedRoute>
            <ZagitThreeTabs />
            // </ProtectedRoute>
          }
        />
      </Routes>
      <Foter /> {/* Footer will show up on all routes */}
    </div>
  );
}

export default App;
