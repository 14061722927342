import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    // Check if a user exists in local storage
    const user = JSON.parse(localStorage.getItem("user"));

    // If user doesn't exist, redirect to login
    if (!user) {
        return <Navigate to="/login" replace />;
    }

    // Return children if the user is authenticated
    return children;
};

export default ProtectedRoute;
