import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { ApiRequests } from "../service/ApiRequests";
import { supabase } from "../supabaseClient";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
  });

  const initialValues = { email: "", password: "" };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await ApiRequests.login(values);

      localStorage.setItem(
        "app-access-token",
        JSON.stringify(response?.data?.user?.session?.access_token)
      );
      localStorage.setItem("user", JSON.stringify(response?.data?.user?.user));
      if (response.status !== 200) {
        toast.error(response.data.message);
        return;
      }
      toast.success("Logged in successfully");
      resetForm();
      navigate("/");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOAuthSignUp = async (provider) => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: process.env.REACT_APP_CALLBACK_URL,
        },
      });

      if (error) throw error;

      // You can handle any post-login logic here if needed
    } catch (error) {
      console.error("OAuth error:", error.message);
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: "#0d1e67" }}>
        <div className="container" style={{ maxWidth: 1170 }}>
          {/* Navbar */}
        </div>
      </div>

      <div className="login_card_box sections-margin">
        <div className="container">
          <div className="Process-cards login_cards">
            <h2 className="text-center pb-5">Login Page</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email">E-mail</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className="input-form-box"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="my-4">
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="input-form-box"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="blue-btn w-100 text-center"
                  >
                    {loading ? "Logging in..." : "Log In"}
                  </button>
                  <div className="already-account-line-main">
                    <p className="text-center already-account-line py-3">
                      Don’t have an account?
                    </p>
                    <Link className="nav-link" to="/signup">
                      Signup
                    </Link>
                  </div>
                </form>
              )}
            </Formik>
            <div>
              <div className="Or_content">
                <hr />
                <p>OR</p>
                <hr />
              </div>
              <div className="mt-3">
                {/* <button
                  className="blue-btn w-100 text-center"
                  type="button"
                  onClick={() => handleOAuthSignUp("facebook")}
                  style={{ height: "auto", padding: "10px 30px 11px 30px" }}
                >
                  <p className="icon_box">
                    <i className="fa-brands fa-facebook-f" />
                  </p>
                  Signup with Facebook
                </button> */}
                <button
                  className="google-btn blue-btn w-100 text-center"
                  type="button"
                  onClick={() => handleOAuthSignUp("google")}
                  style={{ height: "auto", padding: "10px 30px 11px 30px" }}
                >
                  <p className="icon_box">
                    <i className="fa-brands fa-google" />
                  </p>{" "}
                  Signin with Google
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
