import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const generaterandomname = (username) => {
    const randomId = Math.random().toString(36).substr(2, 4);
    return `${username}-${randomId}`
        .toLowerCase()
        .replace(/[^a-z0-9-]/g, "")
        .substr(0, 63);
};

const requiredParameters = {
    gcp: {
        "gcp-coldline": [
            "bucket_name"
        ],
        'gcp-loadbalancer': [
            'backend_service_name'
        ],
        "gcp-persistantdisk": [
            "persistant_disk_name"
        ],
        "gcp-bigquery": [
            "dataset_id",
            "table_name",
        ],
        "gcp-pubsub": [
            "topic",
            "subscription",
        ],
        "gcp-kms": [
            "key_ring_name",
            "crypto_key_name",
        ],
        'gcp-cloudfunctions': [
            'bucket_name',
            'temp_gcs_bucket',
            'cloud_function_name'
        ],
        'gcp-computeengine': [
            'fire_wall_rule_name',
            'subnet_name',
            'network_name',
            'vm_name',
            'machine_type',
            'vm_image',
        ],
        'gcp-clouddns': [
            'dns_name',
            'zone_description',
            'zone_name'
        ],
        'gcp-vpc': ['network_name', 'subnet_name', 'ip_cidr_range'],
        'gcp-cloudarmor': [
            'instance_template_name_prefix',
            'machine_type',
            'network_name',
            'instance_group_manager_name',
            'instance_base_name',
            'target_size',
            'backend_service_name',
            'health_check_name',
            'security_policy_name'
        ],
        'gcp-datafusion': [
            'bucket',
            'bucket_name_suffix',
            'gcs_csv_file_path',
            'dataset_id',
            'table_id',
            'data_fusion_instance_name',
            'data_fusion_instance_type',
            'data_fusion_instance_version',
            'environment_label',
            'pipeline_name'
        ],
        'gcp-cloudspanner': [
            'zone_name',
            'account_id',
            'display_name',
            'db_name',
            'instance_name'
        ],
        'gcp-memorystore': [
            'bucket_name',
            'redis_instance_name',
            'redis_memory_size_gb',
            'network_name',
            'subnetwork_name',
            'subnetwork_ip_cidr_range',
            'service_account_id',
            'service_account_display_name'
        ],
        'gcp-appengine': [
            'bucket',
            'bucket_prefix',
            'app_bucket_suffix',
            'deployment_version',
            'app_engine_service',
            'app_engine_runtime',
            'app_engine_entrypoint'
        ],
        'gcp-kubernetes': [
            'cluster_name',
            'service_name',
            'initial_node_count',
            'location',
            'image',
            'container_port'
        ],
        'gcp-cloudrun': [
            'image_name',
            'service_name'
        ]
    },
    aws: {
        "aws-glacier": [
            "bucket_name"
        ],
        'aws-elasticloadbalancer': [
            'backend_service_name',
        ],
        "aws-elasticblockstorage": [
            "volume_name"
        ],
        "aws-kinesisstream": [
            "kinesis_stream_name",
            "shard_count",
        ],
        "aws-kms": [
            "key_name",
            "key_description"
        ],
        'aws-route53': ['hosted_zone_name'],
        'aws-lambda': [
            'bucket_name',
            'function_name',
            'runtime',
            // 'handler',
            // 'prefix_ignore'
        ],
        'aws-ec2': [
            'instance_name',
            'vpc_cidr',
            'subnet_cidr',
            'ami_id'
        ],
        'aws-vpc': ['vpc_cidr', 'subnet_cidr'],
        'aws-waf': ['rule_metric_name', 'acl_metric_name'],
        'aws-redshift': [
            'security_group_name',
            'security_group_description',
            'subnet_group_name',
            'cluster_identifier',
            'redshift_db_name',
            'master_username',
            'master_password',
            'node_type',
            'cluster_type',
            's3_bucket_name',
            's3_csv_file_name',
            'redshift_table',
            'iam_role_arn',
        ],
        'aws-rds': [
            'vpc_cidr',
            'availability_zones',
            // 'availability_zones',
            'subnet_cidrs',
            // "subnet_cidrs",
            'db_subnet_group_name',
            'security_group_name',
            'rds_ingress_port',
            // 'rds_ingress_cidr_blocks',
            'db_allocated_storage',
            'db_storage_type',
            'db_engine',
            'db_engine_version',
            'db_instance_class',
            'db_identifier',
            'db_username',
            'db_password',
            'db_parameter_group_name',
            'db_skip_final_snapshot',
        ],
        'aws-kubernetes': [
            'cluster_name_prefix',
            'vpc_name',
            'cidr',
            // 'private_subnets',
            // 'public_subnets',
            // 'cluster_version',
            // 'instance_types',
            // 'node_group_one',
            // 'node_group_two',
            // 'enable_nat_gateway',
            // 'single_nat_gateway',
            // 'enable_dns_hostnames',
            // 'public_subnet_tags',
            // 'private_subnet_tags',
        ],
        'aws-firegate': ['ecs_task_definition_name', 'ecs_service_name'],
        'aws-beanstalk': [
            'language',
            'instance_type',
            'app_name',
            'app_description',
        ],
    },
    azure: {
        "azure-archivestorage": [
            "container_name"
        ],
        'azure-loadbalancer': [
            'backend_service_name'
        ],
        "azure-manageddisks": [
            "resource_group_name",
            "disk_name",
        ],
        "azure-cosmosdb": [
            "resource_group_name",
            "cosmosdb_name",
        ],
        "azure-servicebus": [
            "service_bus_namespace_name",
            "queue_name",
            "topic_name",
            "subscription_name",
        ],
        "azure-keyvault": [
            "resource_group_name",
            "vault_name",
            "key_name",
        ],
        "azure-cdn": [
            "resource_group_name",
            "storage_account_name",
            "profile_name",
            "endpoint",
            "origin_name",
            "host_name",
            // 

            // "azure-keyvalut": "",
            // "resource_group_name": "zagit-keyvalut",
            // "vault_name": "zagit-keyvalut",
            // "key_name": "zagit-keyvalut",




            //   "resource_group_name": "harigcpwork",
            //   "storage_account_name": "harigcpvm",
            // 	"profile_name": "harigcpvm",
            // 	"endpoint": "harigcpvm",
            // 	"origin_name": "harigcpvm",
            // 	"host_name": "harigcpvm",
        ],
        'azure-vm': [
            'resource_group_name',
            // 'location',
            'vm_name',
            'admin_username',
            'admin_password',
            'vm_size',
        ],
        'azure-functions': [
            'resource_group_name',
            'storage_account_name',
            'app_service_plan_name',
            'function_app_name'
        ],
        'azure-trafficmanager': [
            'resource_group_name',
            'traffic_manager_profile_name',
            'traffic_manager_endpoint_name',
            // 'traffic_routing_method',
            'endpoint_fqdn',
            // 'endpoint_type'
        ],
        'azure-vpc': ['address_space', 'ip_cidr_range'],
        'application-gateway': [
            'resource_group_name',
            'public_ip_name',
            'app_gateway_name',
        ],
        'azure-rediscache': [
            'resource_group_name',
            'redis_capacity',
            'redis_family',
            'redis_sku_name',
            'redis_enable_non_ssl_port',
        ],
        'azure-kubernetes': [
            'cluster_name',
            'node_pool_name',
            'node_count',
            'vm_size',
            'identity_type',
        ],
        'azure-datafactory': [
            'resource_group_name',
            'storage_account_name',
            'container_name',
            'cosmosdb_account_name',
            'cosmosdb_database_name',
            'cosmosdb_container_name',
            'cosmosdb_partition_key_path',
            'data_factory_name',
            'csv_file_path',
            'cosmosdb_linked_service_name',
            'storage_linked_service_name',
            'pipeline_name',
            'source_container',
            'destination_container',
            'source_dataset',
            'destination_dataset',
        ],
        'azure-containerservice': [
            'resource_group_name',
            'container_name',
            'container_image',
            'container_cpu',
            'container_memory',
            'docker_username',
            'docker_password',
        ],
        'azure-appservice': [
            'tier',
            'app_service_plan_sku',
            'app_service_name',
            'app_service_plan_name',
            'resource_group_name',
        ],
    },
};

// Move serviceParameters outside the component
const serviceParameters = {
    "gcp-coldline": {
        bucket_name: "replace-zagit-coldname"
    },
    'gcp-loadbalancer': {
        backend_service_name: 'replace-backend-service-name',
    },
    "gcp-persistantdisk": {
        persistant_disk_name: "replace-zagit-disk-name"
    },
    "gcp-bigquery": {
        dataset_id: "replace-gcp-data-set",
        table_name: "replace-gcp-table-id",
    },
    "gcp-pubsub": {
        topic: "replace-zagit-topic",
        subscription: "replace-zagit-topic",
    },
    "gcp-kms": {
        key_ring_name: `replace-zagitio-keyring`,
        crypto_key_name: `replace-zagito-cryptokey`,
    },
    'gcp-cloudfunctions': {
        bucket_name: 'replace-bucket-name',
        temp_gcs_bucket: 'replace-temp-gcs-bucket',
        cloud_function_name: 'replace-cloud-function-name'
    },
    'gcp-computeengine': {
        // firewall_rule_name: "allow-ssh-zagit"
        fire_wall_rule_name: 'replace-allow-ssh-zagit',
        subnet_name: "replace-com-engine-zag",
        network_name: "replace-zagitcomutename",
        vm_name: "flask-vm",
        machine_type: "f1-micro",
        vm_image: "debian-cloud/debian-11"
    },
    'gcp-clouddns': {
        dns_name: 'replace-example-dns-name',
        zone_description: 'replace-Example DNS zone',
        zone_name: 'replace-example-zone-name'
    },
    "gcp-vpc": {
        network_name: "replace-default-vpc-network",
        subnet_name: "replace-default-vpc-subnet",
        ip_cidr_range: "10.19.0.0/16",
    },
    "gcp-cloudarmor": {
        instance_template_name_prefix: "replace-my-zagit-name",
        machine_type: "replace-n1-standard-1",
        network_name: "replace-default",
        instance_group_manager_name: "replace-my-igm1",
        instance_base_name: "replace-my-name-base",
        target_size: "2",
        backend_service_name: "replace-my-backend-serviceumer",
        health_check_name: "replace-my-health-check1",
        security_policy_name: "replace-my-security-policy1",
    },
    'gcp-datafusion': {
        bucket: "my-datafusion-bucket",
        bucket_name_suffix: "df-suffix",
        gcs_csv_file_path: "gs://my-datafusion-bucket/data.csv",
        data_fusion_instance_name: "my-data-fusion-instance",
        data_fusion_instance_type: "BASIC",

        dataset_id: 'xyz',
        table_id: 'xyz',
        data_fusion_instance_version: 'xyz',
        environment_label: 'xyz',
        pipeline_name: 'xyz',
    },
    'gcp-cloudspanner': {
        zone_name: "us-central1-f",
        account_id: "my-svc-account",
        display_name: "replace-my-svc-account",
        db_name: "my-database",
        instance_name: "replace-my-instance"
    },
    'gcp-memorystore': {
        bucket_name: "globalcloud-cloudfunctions",
        redis_instance_name: "replace-my-instance",
        redis_memory_size_gb: "1",
        network_name: "replace-memeorystore-globalcloud-001",
        subnetwork_name: "replace-memeorystore-subnet-001",
        subnetwork_ip_cidr_range: "10.0.0.0/16",
        service_account_id: "my-sa-memorystore",
        service_account_display_name: "replace-My Service Account"
    },

    'gcp-appengine': {
        bucket: "ts-appengine-terraform-tfstate",
        bucket_prefix: "ts-appengine-terraform/state",
        app_bucket_suffix: "rplayer",
        deployment_version: "v1",
        app_engine_service: "default",
        app_engine_runtime: "nodejs20",
        app_engine_entrypoint: "node index.js"


    },
    'gcp-kubernetes': {
        cluster_name: "replace-gke-standard-regional-cluster",
        service_name: "replace-example-hello-app-loadbalancer",
        initial_node_count: "1",
        location: "us-central1",
        image: "us-docker.pkg.dev/google-samples/containers/gke/hello-app:2.0",
        container_port: "8080"
    },
    'gcp-cloudrun': {
        image_name: "replace-my-app",
        service_name: "replace-my-service",
    },

    // aws
    "aws-glacier": {
        bucket_name: "replace-zagit-glacier"
    },
    'aws-elasticloadbalancer': {
        backend_service_name: 'replace-backend-service-name',
    },
    "aws-elasticblockstorage": {
        volume_name: "replace-zagit-storage"
    },
    "aws-kinesisstream": {
        kinesis_stream_name: "replace-my-stream",
        shard_count: "1",
    },
    "aws-kms": {
        key_name: `replace-my-kmszag`,
        key_description: `replace-my-kmszag`
    },
    'aws-route53': {
        hosted_zone_name: 'replace-zagit.io',
    },
    'aws-lambda': {
        bucket_name: 'replace-globalcloud-etl',
        function_name: 'replace-sample-lambda',
        runtime: 'python3.8',
        // handler: 'lambda_function.lambda_handler',
        // prefix_ignore: 'runtime',
    },
    'aws-ec2': {
        instance_name: 'replace-my-instance',
        vpc_cidr: '10.0.0.0/16',
        subnet_cidr: '10.0.1.0/24',
        ami_id: 'ami-0e1c5d8c23330dee3',
    },
    "aws-vpc": {
        vpc_cidr: "10.19.0.0/16",
        subnet_cidr: "10.0.1.0/24",
    },
    "aws-waf": {
        rule_metric_name: "replace-default-waf-rule-metric",
        acl_metric_name: "replace-default-acl-metric",
    },

    'aws-redshift': {
        security_group_name: "replace-redshift-sg",
        security_group_description: "replace-Redshift Security Group",
        subnet_group_name: "replace-new-example1",
        cluster_identifier: "replace-my-redshift-cluster",
        redshift_db_name: "mydatabase",
        master_username: "globalcloud",
        master_password: "Saani12345",
        node_type: "dc2.large",
        cluster_type: "single-node",
        s3_bucket_name: "gabriel-aws-redshift",
        s3_csv_file_name: "sample.csv",
        redshift_table: "replace-my_table",
        iam_role_arn: "arn:aws:iam::792196125483:role/aws"
    },
    'aws-rds': {
        vpc_cidr: "10.0.0.0/16",
        availability_zones: "us-east-1a",
        // availability_zones: "us-east-1b",
        // subnet_cidrs: ["10.0.1.0/24", "10.0.2.0/24"],
        subnet_cidrs: "10.0.1.0/24",
        // subnet_cidrs: "10.0.2.0/24",
        db_subnet_group_name: "replace-globalcloud-rds-mysql",
        security_group_name: "replace-rds-sg",
        rds_ingress_port: "3306",
        // rds_ingress_cidr_blocks: ["0.0.0.0/0"],
        rds_ingress_cidr_blocks: "0.0.0.0/0",
        db_allocated_storage: "20",
        db_storage_type: "replace-gp2",
        db_engine: "mysql",
        db_engine_version: "5.7",
        db_instance_class: "db.t3.micro",
        db_identifier: "mydb",
        db_username: "example",
        db_password: "examplepassword",
        db_parameter_group_name: "default.mysql5.7",
        db_skip_final_snapshot: "true"
    },
    'aws-kubernetes': {
        cluster_name_prefix: "replace-education-eks",
        vpc_name: "replace-education-vpc",
        cidr: "10.0.0.0/16",
        // private_subnets: ["10.0.1.0/24", "10.0.2.0/24", "10.0.3.0/24"],
        // public_subnets: ["10.0.4.0/24", "10.0.5.0/24", "10.0.6.0/24"],
        // cluster_version: "1.29",
        // instance_types: ["t3.small"],
        // node_group_one: { "min_size": 1, "max_size": 3, "desired_size": 2 },
        // node_group_two: { "min_size": 1, "max_size": 2, "desired_size": 1 },
        // enable_nat_gateway: true,
        // single_nat_gateway: true,
        // enable_dns_hostnames: true,
        // public_subnet_tags: { "kubernetes.io/role/elb": 1 },
        // private_subnet_tags: { "kubernetes.io/role/internal-elb": 1 }
    },
    'aws-firegate': {
        ecs_task_definition_name: "replace-my-task-defination",
        ecs_service_name: "replace-my_service",
    },
    'aws-beanstalk': {
        language: "Node.js 18",
        instance_type: "replace-t2.micro",
        app_name: "replace-globalcloud-node-app",
        app_description: "replace-app description"

    },



    // azure
    "azure-archivestorage": {
        container_name: "replace-zagit-archive"
    },
    'azure-loadbalancer': {
        backend_service_name: 'replace-backend-service-name',
    },
    "azure-manageddisks": {
        "resource_group_name": "replace-zagit-cosmosdb",
        "disk_name": "replace-zagit-cosmosdb"
    },
    "azure-cosmosdb": {
        resource_group_name: `replace-zagit-cosmosdb`,
        cosmosdb_name: `replace-zagit-cosmosdb`
    },
    "azure-servicebus": {
        service_bus_namespace_name: `replace-zagit-keyvault`,
        queue_name: `replace-zagit-keyvault`,
        topic_name: `replace-zagit-servicebus`,
        subscription_name: `replace-zagit-servicebus`,
    },
    "azure-keyvault": {
        resource_group_name: `replace-zagit`,
        vault_name: `replace-zagit`,
        key_name: `replace-zagit`,
    },
    "azure-cdn": {
        resource_group_name: `replace-harigcpwork`,
        storage_account_name: `replace-harigcpvm`,
        profile_name: `replace-harigcpvm`,
        endpoint: `replace-harigcpvm`,
        origin_name: `replace-harigcpvm`,
        host_name: `replace-harigcpvm`,
    },
    'azure-vm': {
        resource_group_name: 'replace-harigcpwork',
        // location: 'westus',
        vm_name: 'replace-harigcpvm',
        admin_username: 'harigcp',
        admin_password: 'Harigcp12!@',
        vm_size: 'Standard_DS1_v2',
    },
    'azure-functions': {
        resource_group_name: 'replace-harigcp001',
        storage_account_name: 'replace-harigcp001',
        app_service_plan_name: 'replace-harigcp001',
        function_app_name: 'replace-harigcp001'
    },
    'azure-trafficmanager': {
        resource_group_name: 'hari001-resources',
        traffic_manager_profile_name: 'hari001-tm-profile',
        traffic_manager_endpoint_name: 'hari001-tm-endpoint',
        // traffic_routing_method: 'Performance',
        endpoint_fqdn: 'hari001.com',
        // endpoint_type: 'ExternalEndpoint'
    },
    "azure-vpc": {
        vpc_cidr: "10.19.0.0/16",
        subnet_cidr: "10.1.1.0/24",
    },
    "application-gateway": {
        resource_group_name: "replace-my-resource-group",
        public_ip_name: "replace-my-public-ip",
        app_gateway_name: "replace-my-app-gateway",
    },



    'azure-rediscache': {
        resource_group_name: "rg-example1111",
        redis_capacity: "0",
        redis_family: "C",
        redis_sku_name: "Basic",
        redis_enable_non_ssl_port: false
    },
    'azure-kubernetes': {
        resource_group_name: "rg-example",
        cluster_name: "example-aks_azure",
        node_pool_name: "default",
        node_count: "1",
        vm_size: "Standard_DS2_v2",
        identity_type: "SystemAssigned"
    },
    'azure-datafactory': {
        resource_group: "rg-example",
        storage_account_name: "stgacctexample",
        container_name: "example-container",
        cosmosdb_account_name: "cosmosdbgcp",
        cosmosdb_database_name: "cosmosdbexampledb",
        cosmosdb_container_name: "cosmosdbexamplecontainer",
        cosmosdb_partition_key_path: "/Index",
        data_factory_name: "adfexampleglobalcloud",
        csv_file_path: "sample.csv",
        cosmosdb_linked_service_name: "CosmosDBService",
        storage_linked_service_name: "StorageService",
        pipeline_name: "CopyPipeline",
        source_container: "example-container",
        destination_container: "cosmosdbexamplecontainer",
        source_dataset: "BlobDataset",
        destination_dataset: "CosmosDBDataset"
    },
    'azure-containerservice': {
        resource_group_name: "rg-example",
        container_name: "mycontainerinstance",
        container_image: "nginx:latest",
        container_cpu: "1",
        container_memory: "1.5",
        docker_username: "alaskaerp",
        docker_password: "UmerSinghera786!@",
        resource_group_name: "resource_group_name"
    },
    'azure-appservice': {
        tier: "Basic",
        app_service_plan_sku: "B1",
        app_service_name: "myAppServiceglobalhari",
        app_service_plan_name: "myAppServicePlan",
        resource_group_name: "myAppServiceRG"
    },
};

const DynamicForm = ({
    platform,
    service,
    inputParams,
    handleParamChange,
    selectedValue,
    username,
    sessionIndex,
}) => {
    const [generatedName, setGeneratedName] = useState("");
    const [defaultParams, setDefaultParams] = useState({});

    const requiredParams = requiredParameters[platform]?.[service] || [];

    useEffect(() => {
        if (username && selectedValue === "true") {
            const newName = generaterandomname(username);
            setGeneratedName(newName);
        }
    }, [username, selectedValue]);

    useEffect(() => {
        if (selectedValue === "true" && username) {
            const newDefaults = {};
            requiredParams.forEach((param) => {
                if (typeof serviceParameters[service]?.[param] === "string") {
                    newDefaults[param] = serviceParameters[service][param].replace(
                        /replace-/g,
                        `${generatedName}-`
                    );
                } else {
                    newDefaults[param] = "";
                }
            });
            setDefaultParams(newDefaults);
        }
    }, [selectedValue, username, requiredParams, generatedName, service]);



    const formatLabel = (str) =>
        str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

    return (
        <Form>
            {requiredParams.map(
                (param) =>
                    selectedValue === "true" && (
                        <Form.Group key={`${param}-${sessionIndex}`}>
                            <Form.Label>{formatLabel(param)}</Form.Label>
                            <Form.Control
                                type="text"
                                name={param}
                                value={
                                    inputParams?.[sessionIndex]?.[service]?.[param] ||
                                    defaultParams[param] ||
                                    ""
                                }
                                onChange={handleParamChange}
                                placeholder={`Enter ${formatLabel(param)} for Session ${sessionIndex + 1
                                    }`}
                            />
                        </Form.Group>
                    )
            )}
        </Form>
    );
};

export default DynamicForm;
