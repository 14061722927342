import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import { Link, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import axios from "axios";
import { baseUrl } from "../constants";
import { ApiRequests } from "../service/ApiRequests";
import { toast } from "react-toastify";

function Signup() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .required("Username is required")
      .min(3, "Username must be at least 3 characters")
      .max(20, "Username cannot be longer than 20 characters"),

    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),

    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&]/,
        "Password must contain at least one special character"
      ),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });
  const initialValues = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      delete values.confirmPassword;
      const response = await ApiRequests.register({ ...values });

      if (response.status !== 201) return toast.error(response.data.message);
      toast.success("Account created successfully");
      navigate(`/login`);
      resetForm();
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOAuthSignUp = async (provider) => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: process.env.REACT_APP_CALLBACK_URL,
        },
      });

      if (error) throw error;

      // You can handle any post-login logic here if needed
    } catch (error) {
      console.error("OAuth error:", error.message);
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: "#0d1e67" }}>
        <div className="container" style={{ maxWidth: 1170 }}>
          {/* Navbar */}
        </div>
      </div>
      {/* Signup card */}
      <div className="login_card_box sections-margin">
        <div className="container">
          <div className="Process-cards login_cards">
            <h2 className="text-center pb-5">Signup Page</h2>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                return (
                  <>
                    <div>
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="firstName"
                        className="input-form-box"
                        id="firstName"
                        name="firstName"
                        placeholder="Enter first name"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div>
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="lastName"
                        className="input-form-box"
                        id="lastName"
                        name="lastName"
                        placeholder="Enter last name "
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div>
                      <label htmlFor="userName">username</label>
                      <input
                        type="userName"
                        className="input-form-box"
                        id="userName"
                        name="userName"
                        placeholder="username"
                        value={values.userName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="userName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div>
                      <label htmlFor="email">email</label>
                      <input
                        type="email"
                        className="input-form-box"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="my-4">
                      <label htmlFor="password">Create Password</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        className="input-form-box"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="my-4">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className="input-form-box"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <button
                      onClick={handleSubmit}
                      disabled={loading}
                      className="blue-btn w-100 text-center"
                      type="submit"
                      style={{ height: "auto", padding: "15px 30px 16px 30px" }}
                    >
                      {loading ? "Loading..." : "Create Account"}
                    </button>
                  </>
                );
              }}
            </Formik>
            <div className="already-account-line-main">
              <p className="text-center already-account-line py-3">
                Already have an account?
              </p>
              <Link className="nav-link" to="/Login ">
                Login
              </Link>
            </div>
            <div>
              <div className="Or_content">
                <hr />
                <p>OR</p>
                <hr />
              </div>
              <div className="mt-3">
                {/* <button
                  className="blue-btn w-100 text-center"
                  type="button"
                  onClick={() => handleOAuthSignUp("facebook")}
                  style={{ height: "auto", padding: "10px 30px 11px 30px" }}
                >
                  <p className="icon_box">
                    <i className="fa-brands fa-facebook-f" />
                  </p>
                  Signup with Facebook
                </button> */}
                <button
                  className="google-btn blue-btn w-100 text-center"
                  type="button"
                  onClick={() => handleOAuthSignUp("google")}
                  style={{ height: "auto", padding: "10px 30px 11px 30px" }}
                >
                  <p className="icon_box">
                    <i className="fa-brands fa-google" />
                  </p>{" "}
                  Signup with Google
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer-portion */}
    </div>
  );
}

export default Signup;
